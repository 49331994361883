import React, {FC, useEffect, useRef, useState} from 'react'
import {CustomSelectArrow} from "../../../../svg";
import cls from "../forms.module.scss";
import {setFormElementValue} from "../../../../store/formbuilder";
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../../../../types/state";
import ErrorMessages from "./ErrorMessages";
import {TranslationsType} from "../../../../types/homePageTypes";

interface IProps {
    formId: number,
    index: number,
    element: {
        className: string,
        description: string,
        descriptionShow: boolean,
        label: string,
        name: string,
        placeholder: string,
        required: boolean,
        multiple: boolean,
        values: [
            {
                label: string,
                selected: boolean,
                value: string,
            }
        ],
    },
    translationsData: TranslationsType
}

const Select: FC<IProps> = (
    {
        formId,
        index,
        element: {
            className = "",
            description = "",
            descriptionShow = false,
            label = "",
            name = "",
            placeholder = "",
            required = false,
            multiple = false,
            values = [
                {
                    label: "",
                    selected: false,
                    value: ""
                }
            ],
        },
        translationsData
    }
) => {
    const [open, setOpen] = useState<boolean>(false)
    const reduxValues = useSelector((state: IState) => state.formBuilder.forms.find(el => el.id === formId)?.formData[index].values || [])
    const errors = useSelector((state: IState) => state.formBuilder.errors)
    const selectRef = useRef<any>(null)
    const dispatch = useDispatch();

    useEffect(() => {
        const handleEvent = (e: MouseEvent) => {
            if (selectRef && selectRef.current && !selectRef.current.contains(e.target)) {
                setOpen(false)
            }
        }

        document.addEventListener("mousedown", handleEvent)

        return () => document.removeEventListener("mousedown", handleEvent)
    }, []);

    const handleChangeValue = (e: React.MouseEvent<HTMLDivElement>) => {
        const newValues = reduxValues.map(el => {
            return {
                ...el,
                selected: multiple
                    ? el.value === (e.target as HTMLDivElement).id
                        ? !el.selected
                        : el.selected
                    : el.value === (e.target as HTMLDivElement).id
            }
        })

        dispatch(setFormElementValue({
            id: formId,
            index,
            fieldsForChange: {
                values: newValues
            }
        }))
        setOpen(multiple)
    }

    const handleClasses = {
        true: {
            true: `${cls.select} ${className} ${cls.warning} ${cls.open}`,
            false: `${cls.select} ${className} ${cls.warning} `
        },
        false: {
            true: `${cls.select} ${className} ${cls.open}`,
            false: `${cls.select} ${className}  `
        }
    }

    const handleRequired = {
        true: <span className={cls.required}>*</span>,
        false: null
    }

    const handleDescription = {
        true: <div className={cls.description}>
            <span>?</span>
            <div className={cls.info_text}>{description}</div>
        </div>,
        false: null
    }

    const handlePlaceholder = {
        true: reduxValues.find(el => el.selected)?.label,
        false: placeholder
    }

    const handleOpen = {
        true: cls.open,
        false: ""
    }

    return <div className={cls.form_select}>
        <div className={cls.label}>
            <label htmlFor={name}>
                {label}
            </label>
            {handleRequired[`${required}`]}
            {handleDescription[`${descriptionShow}`]}
        </div>
        <div className={`${handleClasses[`${!!errors[name]}`][`${open}`]}`} ref={selectRef}>
            <div className={cls.select_button} onClick={() => setOpen(!open)}>
                {handlePlaceholder[`${!!reduxValues.find(el => el.selected)}`]}
                <CustomSelectArrow className={handleOpen[`${open}`]}/>
            </div>
            <div className={cls.select_options}>
                {reduxValues.map(({
                                      label = "",
                                      selected = false,
                                      value = ""
                                  }) => {

                    const handleMultiple = {
                        true: {
                            true: <div className={`${cls.option} ${selected ? cls.selected : ""}`} key={value}>
                                <div id={value} onClick={handleChangeValue}>{label}</div>
                            </div>,
                            false: <div className={`${cls.option} ${selected ? cls.selected : ""}`} key={value}>
                                <div id={value} onClick={handleChangeValue}>{label}</div>
                            </div>
                        },
                        false: {
                            true: <div className={cls.option} key={value}>
                                <div id={value} onClick={handleChangeValue}>{label}</div>
                            </div>,
                            false: null
                        }
                    }

                    return handleMultiple[`${multiple}`][`${!selected}`]
                })}
            </div>
        </div>
        <ErrorMessages translationsData={translationsData} errors={errors[name]}/>
    </div>
}
export default Select
