import React, {FC} from 'react'
import cls from "../forms.module.scss"
import {useDispatch, useSelector} from "react-redux";
import {setFormElementValue} from "../../../../store/formbuilder";
import {IState} from "../../../../types/state";
import {OtherInput} from "./index";
import ErrorMessages from "./ErrorMessages";
import {TranslationsType} from "../../../../types/homePageTypes";

interface IProps {
    formId: number,
    index: number,
    element: {
        className: string,
        description: string,
        descriptionShow: boolean,
        inline: boolean,
        label: string,
        name: string,
        other: boolean,
        required: boolean,
        values: [
            {
                label: string,
                selected: boolean,
                value: string,
            }
        ],
    },
    translationsData: TranslationsType
}

const Radio: FC<IProps> = (
    {
        formId,
        index,
        element: {
            className = "",
            description = "",
            descriptionShow = false,
            inline = false,
            label = "",
            name = "",
            other = false,
            required = false,
            values = [
                {
                    label: "",
                    selected: false,
                    value: ""
                }
            ],
        },
        translationsData
    }
) => {
    const reduxValues = useSelector((state: IState) => state.formBuilder.forms.find(el => el.id === formId)?.formData[index].values || [])
    const errors = useSelector((state: IState) => state.formBuilder.errors)
    const dispatch = useDispatch();

    const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValues = reduxValues.map(el => {
            return {
                ...el,
                selected: el.value === e.target.value
            }
        })
        dispatch(setFormElementValue({
            id: formId,
            index,
            fieldsForChange: {
                values: newValues
            }
        }))
    }

    const hasOtherOption = {
        true: <OtherInput
            formId={formId}
            index={index}
            name={name}
            type="radio"
        />,
        false: null
    }

    const handleRequired = {
        true: <span className={cls.required}>*</span>,
        false: null
    }

    const handleDescription = {
        true: <div className={cls.description}>
            <span>?</span>
            <div className={cls.info_text}>{description}</div>
        </div>,
        false: null
    }

    const handleOptionsStyle: { true: {}, false: {} } = {
        true: {flexDirection: "row"},
        false: {flexDirection: "column"}
    }

    return <div className={`${cls.form_radio} ${className}`}>
        <div className={cls.label}>
            <label htmlFor={name}>
                {label}
            </label>
            {handleRequired[`${required}`]}
            {handleDescription[`${descriptionShow}`]}
        </div>
        <div className={cls.options} style={handleOptionsStyle[`${inline}`]}>
            {reduxValues.map(({
                                  label = "",
                                  selected = false,
                                  value = ""
                              }) => {
                const handleOther = {
                    true: <div className={cls.option} key={name + " " + value}>
                        <input
                            id={name + " " + value}
                            type="radio"
                            name={name}
                            value={value}
                            checked={selected}
                            onChange={handleChangeValue}
                        />
                        <label htmlFor={name + " " + value}>{label}</label>
                    </div>,
                    false: null
                }

                return handleOther[`${label !== "other"}`]
            })}
            {hasOtherOption[`${other}`]}
        </div>
        <ErrorMessages translationsData={translationsData} errors={errors[name]}/>
    </div>
}
export default Radio
