import React, {FC} from 'react'
import {TranslationsType} from "../../../../types/homePageTypes";

const ErrorMessages: FC<{ errors: Array<string>, translationsData: TranslationsType }> = ({errors = [],translationsData}) => {

    return <>
        {
            errors.map(error => {
                return <div className="alert-danger" key={error}>
                    {translationsData.RequiredField}
                </div>
            })
        }
    </>
}
export default ErrorMessages;
