import React, { useState } from "react";
import { IProps } from "./types";

import cls from "./dropList.module.scss"

const DropList: React.FC<IProps> = ({ item: { convertedSettings: { styles, parentStyles }, dropList } }) => {
    const [selected, setSelected] = useState<{ content: string; isPDF: boolean; id: number } | null>(null)

    const containsPDFLink = (htmlString: string) => {
        if (typeof window === 'undefined') return false;
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const links = doc.querySelectorAll('a');
        return Array.from(links).some(link => link.href.endsWith('.pdf'));
    };

    return (
        <div style={parentStyles}>
            <ul style={styles} className={cls.dropList}>
                {dropList.map(({ title, content }, i) => {
                    const isPDF = containsPDFLink(content);
                    return (
                        <li className={cls.dropListItem} key={i}>
                            <span
                                className={`${cls.dropListItemTitle} ${selected?.id === i && cls.selectedTitle}`}
                                onClick={(ev) => {
                                    ev.stopPropagation()
                                    setSelected(prev => {
                                        return (prev === null || prev?.id !== i) ? { content, isPDF, id: i } : null
                                    })
                                }}
                            >
                                {title}
                            </span>
                        </li>
                    )
                }
                )}
                {selected && (
                    <div className={cls.dropListItemDecr} >
                        <div className={cls.content} dangerouslySetInnerHTML={{ __html: selected.content }} />
                    </div>
                )}
            </ul>
        </div>)
}

export default DropList