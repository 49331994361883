import React, {FC} from 'react'
import cls from "../forms.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {setFormElementValue} from "../../../../store/formbuilder";
import {IState} from "../../../../types/state";
import ErrorMessages from "./ErrorMessages";
import {TranslationsType} from "../../../../types/homePageTypes";

interface IProps {
    formId: number,
    index: number,
    element: {
        required: boolean,
        label: string,
        description: string,
        descriptionShow : boolean,
        placeholder: string,
        className: string,
        name: string,
        value: string,
        maxlength: number,
        rows: number,
    },
    translationsData: TranslationsType
}

const TextArea: FC<IProps> = (
    {
        formId,
        index,
        element: {
            required = false,
            label = "",
            description = "",
            descriptionShow = false,
            placeholder = "",
            className = "",
            name = "",
            value = "",
            maxlength,
            rows = 0,
        },
        translationsData
    }
) => {
    const reduxValue = useSelector((state: IState) => state.formBuilder.forms.find(el => el.id === formId)?.formData[index].value || "")
    const errors = useSelector((state: IState) => state.formBuilder.errors)
    const dispatch = useDispatch();

    const handleChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        dispatch(setFormElementValue({
            id: formId,
            index,
            fieldsForChange: {
                value: e.target.value
            }
        }))
    }

    const handleClasses = {
        true: `${className} ${cls.warning}`,
        false: className,
    }

    const handleRequired = {
        true: <span className={cls.required}>*</span>,
        false: null
    }

    const handleDescription = {
        true: <div className={cls.description}>
            <span>?</span>
            <div className={cls.info_text}>{description}</div>
        </div>,
        false: null
    }

    return <div className={cls.form_text_area}>
        <div className={cls.label}>
            <label htmlFor={name}>
                {label}
            </label>
            {handleRequired[`${required}`]}
            {handleDescription[`${descriptionShow}`]}
        </div>
        <textarea
            id={name}
            name={name}
            className={handleClasses[`${!!errors[name]}`]}
            placeholder={placeholder}
            value={reduxValue}
            maxLength={maxlength}
            rows={rows}
            onChange={handleChangeValue}
        />
        <ErrorMessages translationsData={translationsData} errors={errors[name]}/>
    </div>
}
export default TextArea
